import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Recaptcha from "react-recaptcha"
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap"
import { StyledForm } from "./styledForm"

const ContactForm = ({ product, location }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all" })

  const [modal, setModal] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [errored, setErrored] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const { i18n, t } = useTranslation("shop")
  const language = i18n.language
  const emailRegex = "^[w-.]+@([w-]+.)+[w-]{2,4}$"

  const onSubmit = async data => {
    const orderDate = new Date()
    const baseUrl = location.origin

    data.product = {}
    data.product["title"] = product.defaultProductVariant.title.translate
    data.product._id = product.id
    data.orderDate = orderDate
    data.product.url = `https://${baseUrl}/en/webshop/${product._rawSlug?.en?.current}`

    if (captcha) {
      setModal(true)

      try {
        const response = await fetch("/.netlify/functions/mailing", {
          method: "POST",
          body: JSON.stringify(data),
        })
        if (!response.ok) {
          setModalMessage(t("mail_error"))
          setModal(true)
          setErrored(true)
          setCaptcha(false)
        } else {
          setModalMessage(t("thank_you_order"))
          setModal(true)
          setErrored(false)
          setCaptcha(false)
        }
      } catch (e) {
        setModalMessage(t("mail_error"))
        setModal(true)
        setErrored(true)
        setCaptcha(false)
        console.log("ERROR with orders!!!", e)
      }
    }
  }

  const onClose = () => {
    // close Modal
    setModal(false)
    // reset form if there was no error
    !errored && reset()
    // redirect
    !errored && navigate(`/${language}/webshop`)
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <h3>{t("form_title")}</h3>
        <label htmlFor="fullName">
          {t("form_name")} <span className="star-mark">*</span>
        </label>
        <input
          placeholder={t("form_name")}
          type="text"
          id="fullName"
          {...register("fullName", {
            required: t("form_name_required"),
          })}
        />
        {errors.fullName && <p>{t("form_name_required")}</p>}
        <label htmlFor="email">
          {t("form_email")} <span className="star-mark">*</span>
        </label>
        <input
          placeholder={"youremail@example.com"}
          type="email"
          id="email"
          {...register("email", {
            required: "Required field",
            maxLength: { value: 50, message: "Max. 50 characters" },
            pattern: {
              value: emailRegex,
              message: t("form_email_valid"),
            },
          })}
        />
        {errors.email && <p>{t("form_email_valid")}</p>}
        <label htmlFor="phone">{t("form_phone")}</label>
        <input
          type="text"
          id="phone"
          placeholder={"+36 80 345 6789"}
          {...register("phone")}
        />

        <label htmlFor="phone">{t("form_message")}</label>
        <input
          type="textarea"
          id="message"
          placeholder={"Your optional message"}
          {...register("message")}
        />

        <label htmlFor="accept_terms">
          <Link className="privacy-link" to={`/${language}/privacy`}>
            {t("accept_terms")} <span className="star-mark">*</span>
          </Link>
        </label>
        <input
          className="checkbox"
          type="checkbox"
          id="accept_terms"
          name="accept_terms"
          placeholder={"+36 80 345 6789"}
          {...register("accept_terms", {
            required: t("please_accept"),
          })}
        />
        {errors.accept_terms && <p>{t("please_accept")}</p>}

        <Recaptcha
          className="mb-5"
          verifyCallback={() => setCaptcha(true)}
          sitekey={"6Lc4JSYcAAAAACbXXPipj36PG9xSNglYEMggO2YA"}
        />
        <button className="submit-button mb-5" role="submit">
          {t("submit")}
        </button>
      </StyledForm>
      <Modal isOpen={modal} toggle={onClose}>
        <ModalBody style={{ fontSize: "16px" }}>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button className="mb-5" color="secondary" onClick={onClose}>
            {t("close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

ContactForm.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
}

export default ContactForm
