import styled from "styled-components"

export const StyledForm = styled.form`
  display: block;
  max-width: 500px;
  margin: 1rem auto;
  transition: all 200ms ease-in-out;
  label,
  input {
    display: block;
    margin: 0.5rem 0;
  }
  p {
    color: #bf1650;
  }

  p:before {
    display: inline;
    content: "⚠ ";
  }

  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: white;
    font-size: 14px;
    font-weight: 200;
    display: inline-block;
  }
  .checkbox {
    width: 15px;
    margin-left: 1rem;
    display: inline-block;
  }
  .privacy-link {
    font-weight: normal;
    color: ${props => props.theme.colors.lightgray};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.white};
      text-decoration: underline;
    }
  }
  .submit-button {
    display: block;
    padding: 0.5rem 0.75rem;
    min-width: 200px;
    color: ${props => props.theme.colors.black};
    font-size: 1.4rem;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    &:hover {
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.black};
      border: ${props => `1px solid ${props.theme.colors.white}`};
    }
    &:active {
      transform: scale(0.98);
      border: ${props => `1px solid ${props.theme.colors.white}`};
      outline: none;
    }
  }
`
