import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ImageCarousel from "../Carousel"
import { Container, Row, Col } from "reactstrap"
import ContactForm from "./form"
import { MediaQueries } from "../../utils/responsive"

const StyledContainer = styled(Container)`
  margin-top: 80px;
  margin-bottom: 80px;

  @media ${MediaQueries.mobileUp} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ProductDetail = ({ product, quantity, location }) => {
  return (
    <StyledContainer>
      <Row className="justify-content-center mb-3">
        <Col xs={12} lg={7} className="px-0">
          <div className="carouselHolder">
            <ImageCarousel
              images={product.defaultProductVariant.images}
              title={product.defaultProductVariant.title.translate}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="text-md-center mb-5">
            {product.defaultProductVariant.title.translate}
          </h2>

          <ContactForm
            location={location}
            product={product}
            quantity={quantity}
          />
        </Col>
      </Row>
    </StyledContainer>
  )
}

ProductDetail.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
}

export default ProductDetail
