import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import ProductDetail from "../components/ProductDetail"
import Layout from "../components/layout"
import { MediaQueries } from "../utils/responsive"

const StyledPage = styled.div`
  margin-bottom: 80px;
  @media ${MediaQueries.mobileUp} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const OrderProduct = ({ data, alternateLinks, location }) => {
  const product = data.sanityProduct

  return (
    <Layout alternateLink={alternateLinks}>
      <StyledPage>
        <SEO title="Justanatomy.com | Order Products" />
        <Container>
          <Row>
            <Col>
              <ProductDetail
                product={product}
                location={location}
                quantity={
                  (typeof location !== undefined &&
                    location?.state?.quantity) ||
                  1
                }
              />
            </Col>
          </Row>
        </Container>
      </StyledPage>
    </Layout>
  )
}

export const query = graphql`
  query OrderProduct($product: String, $language: String) {
    sanityProduct(_id: { eq: $product }) {
      defaultProductVariant {
        title {
          translate(language: $language)
        }
        images {
          asset {
            gatsbyImageData
            description
          }
        }
      }
      id
      _rawBody
      _rawSlug
    }
  }
`

export default OrderProduct
